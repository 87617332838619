import React from "react"

import Layout from "../components/layout"

export default () => (

<Layout>
	 <div id="content">
<h1>Listen</h1>
<h2>Direkte Aufnahmen</h2>
<b>Bach Minuten</b><br/>
Ungefähr eine Minute aus jeder Tanzart der Bachs Solo Cello Suiten.
<p>
<ul>
         <li>Eine Prelude, um einen kühnen Ton vorzustellen:<br/>
		 <a href="/music/1 website bach prelude.mp3"> <i>Prelude (aus der 2. Suite)</i></a></li>
		 <li>Eine Allemande, um die Reiselust zu spären:<br/>
		 <a href="/music/2 website bach allemande.mp3"> <i>Allemande (aus der 5. Suite)</i> </a></li>
		 <li>Ein Courante, für einen erhöhten Puls:<br/>
		 <a href="/music/3 website bach courante.mp3"> <i>Courante (aus der 4. Suite)</i> </a></li>
		 <li>Eine Sarabande, für ein wehmütiges Herz:<br/>
		 <a href="/music/4 website bach sarabande.mp3"> <i>Sarabande (aus der 3. Suite)</i> </a></li>
		 <li>Ein Gavotte, die Tanzträume erweckt:<br/>
		 <a href="/music/5 website bach gavotte.mp3"> <i>Gavotte II (aus der 6. Suite) </i></a></li>
		 <li>Eine Gigue, welche die Füsse antreibt:<br/>
		 <a href="/music/6 website bach gigue.mp3"> <i>Gigue (aus der 1. Suite) </i></a></li>
</ul>

</p><p>
<b>Telemann Solo Fantasia</b><br/>
Fantasia in B-flat major (Largo, Allegro, Grave, Si replica l'allegro)<br/>
aufgenommen im Studio vom Adam Zelny, Worcester, MA<br/>
<ul>
<li><a href="/music/Fantasia 1.1-2.28.13.mp3"> <i>Largo</i></a></li>
<li><a href="/music/Fantasia 1.2-2.28.13.mp3"> <i>Allegro</i></a></li>
<li><a href="/music/Fantasia 1.3-2.28.13.mp3"> <i>Grave</i></a></li>
<li><a href="/music/Fantasia 1.4-2.28.13.mp3"> <i>Si replica l'allegro</i></a></li>
</ul>
</p><p>
<ul>
<b>Auf YouTube:</b><br/>
<a href="https://www.youtube.com/watch?v=lZT8iJB1G4w">Sinfonia Concertante</a><br/>
<a href="https://www.youtube.com/watch?v=lAExrI_4JnY">Corelli Christmas Concerto</a><br/>
</ul>
</p>
<p>
<ul>
<b>Ein Gedicht mit Musik beides von Irma geschrieben:</b><br/>
<li><a href="/music/pit pat poem.mp3">Pit Pat</a></li>
</ul></p>
<p>
<b>Benjamin Britten's Elegy</b><br/>
<ul>
<li><a href="/music/7 website britten elegy.mp3">die erste Hälfte</a></li>
</ul>
</p>
	</div>
</Layout>)
